<template>
    <v-sheet class="rounded-pill mb-5 pt-4 transition-swing grey lighten-4 text-center"
        height="58"
        width="400"
        style="font-size:20px; color:#A5ACB4; float:left;"
    >
        <v-menu
            ref="menu_from_ref"
            v-model="menu_from"
            :close-on-content-click="false"
            :return-value.sync="search_from_day"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <span v-on="on">{{search_from_day}}</span>
            </template>
            <v-date-picker v-model="search_from_day" 
                no-title 
                scrollable
                :max="search_from_max"
                min="2020-03-01"     
                @input="inputFromDay()" 
            >
            </v-date-picker>
        </v-menu>  
        <span class="ml-6 mr-6">~</span>
        <v-menu
            ref="menu_to_ref"
            v-model="menu_to"
            :close-on-content-click="false"
            :return-value.sync="search_to_day"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <span v-on="on">{{search_to_day}}</span>                    
            </template>
            <v-date-picker v-model="search_to_day" 
                no-title 
                scrollable
                :max="search_to_max"
                min="2020-03-01"     
                @input="inputToDay()"
            >
            </v-date-picker>
        </v-menu>              
    </v-sheet>    
</template>
<script>
import dateApi from '@/api/date'

export default {
    props: ['fromDay', 'toDay', 'initType'],
    data: () => ({
        menu_from: false,
        menu_to: false,
        search_from_day: '',
        search_to_day: '',
        search_from_max: '',
        search_to_max: '',
    }),
    watch: {
        fromDay: function(newFromDay) { 
            this.search_from_day = newFromDay
            this.$refs.menu_from_ref.save(this.search_from_day)
        }, 
        toDay: function(newToDay) { 
            this.search_to_day = newToDay
            this.$refs.menu_to_ref.save(this.search_to_day)
        }
    },
    mounted () { 
        const today = dateApi.dateISOString()
        this.search_from_max = today
        this.search_to_max = today

        if(this.initType == 'WEEK') { 
            const aWeekAgo = new Date(new Date())
            aWeekAgo.setDate(aWeekAgo.getDate() - 7)

            this.search_from_day = dateApi.dateISOString(aWeekAgo)    
            this.search_to_day = dateApi.dateISOString()                        
        } else if(this.initType == 'DAY') { 
            this.search_from_day = dateApi.dateISOString()
            this.search_to_day = dateApi.dateISOString()   
        }

        if(this.fromDay) { 
            this.search_from_day = this.fromDay
        }
        if(this.toDay) { 
            this.search_to_day = this.toDay
        }

        this.$emit('inited', { 'from_day': this.search_from_day, 'to_day': this.search_to_day })
    },
    methods: {   
        selChanged () { 
            this.$emit('changed', { 'from_day': this.search_from_day, 'to_day': this.search_to_day })
        },
        inputFromDay () { 
            // @input="$refs.menu_from.save(search_from_day)"                               
            this.$refs.menu_from_ref.save(this.search_from_day)
            this.selChanged()
        },
        inputToDay () { 
            // @input="$refs.menu_to.save(search_to_day)"                               
            this.$refs.menu_to_ref.save(this.search_to_day)
            this.selChanged()
        }
        
    }
}
</script>